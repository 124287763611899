<template>
<div>
  <v-card width="370" class="mx-auto mt-5" flat>
    <Errors v-bind:errors="errors"/>
  </v-card>
</div>
</template>

<script>
import Errors from '@/components/Errors.vue';

export default {
  name: 'ErrorRole',

  props: {
    role: { //date got from route
      type: String,
      default: ''
    },
  },

  components: {
    Errors,
  },

  data() {
    return {
      errors: [],
    }
  },
  created() {
    this.pickErrorMessageForRole()
  },

  methods: {

    /**
     * @description depending on which role the user is missing, the according error text will be provided
     */
    pickErrorMessageForRole(){
      switch(this.role){
        case '':
          this.errors.push({
            text: 'Du hast nicht die nötigen Berechtigungen auf diese Seite zuzugreifen.',
          })
          break;

        case 'admin':
          this.errors.push({
            text: 'Du hast nicht die nötigen Berechtigungen auf diese Seite zuzugreifen.', 
            advice: 'Bitte einen Administrator deines Nachhilfeinstituts das für dich zu machen.',
          })
          break;
      }
    }
  }
}
</script>